import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerCell: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  bodyCell: {
    padding: '16px',
  },
  received: {
    color: '#4caf50',
  },
  pending: {
    color: '#f44336',
  },
});

const SubmissionProgress = ({data}) => {
  const filtered = Object.keys(data).filter(item => item !== "consultantEmail" && item !== "consultantTelephone").reduce((obj, key) => {
    obj[key] = data[key];
    return obj;
  }, {});
  const classes = useStyles();
  const receivedCount = Object.values(filtered).filter(value => value).length;
  const totalCount = Object.values(filtered).length;
  const progress = (receivedCount / totalCount) * 100;

  return (
    <Box>
      <Box padding="10px" bgcolor="#1E3A8A" width="100%" mt="40px">
        <Typography color="white">
          Submission Progress
        </Typography>
      </Box>
      <Box width="890px" ml="20px">
        <Box mt={2} mb={2}>
          <Typography variant="subtitle1">
            {receivedCount} of {totalCount} fields received
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="submission progress table">
            <TableHead>
              <TableRow>
                {Object.keys(filtered).map((key, index) => (
                  <TableCell key={index} className={classes.headerCell}>
                    {key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {Object.values(filtered).map((value, index) => (
                  <TableCell key={index} className={classes.bodyCell}>
                    {value ? (
                      <Typography variant="body1" className={classes.received}>
                        Received
                      </Typography>
                    ) : (
                      <Typography variant="body1" className={classes.pending}>
                        Pending Field
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SubmissionProgress;
