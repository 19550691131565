import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ReactPaginate from "react-paginate";

// Styling
import '../Pagination.css'

// Verification Card Data
import checklistItems from '../data/VerificationsMockData';

const VerificationChecklist = ({handleManageUploads, handleFileUpload, handleUploadFile, selectedFileCount}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (data) => {
    setCurrentPage(data.selected);
  };

  // Set the number of items per page
  const itemsPerPage = 3;
  // Calculate the total number of pages
  const pageCount = Math.ceil(checklistItems.length / itemsPerPage);

  // Slice the checklistItems array to get the items for the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = checklistItems.slice(startIndex, endIndex);

  return (
    <Box>
      <Box
        padding="10px"
        bgcolor="#1E3A8A"
        width="100%"
        mt="40px"
        textAlign="left"
      >
        <Typography color="white">Employer Verification Checklist</Typography>
      </Box>

      {/* Render the items for the current page */}
      {itemsToDisplay.map((item, index) => (
        <Box
          key={index}
          width="890px"
          mt="20px"
          padding="10px"
          border="1px solid #E8E8E8"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
        >
          <Typography fontWeight="bold">{item.title}</Typography>
          <Box>
            <ul>
              {item.items.map((listItem, listItemIndex) => (
                <li key={listItemIndex}>{listItem}</li>
              ))}
            </ul>
          </Box>

          <Box display="flex" alignItems="center" mt="30px">
            <Box mr="10px">
              <Button variant="contained" component="label">
                Select Files ({selectedFileCount[item.title] || 0})
                <input  type="file" hidden multiple onChange={(e) => handleFileUpload(e, item.title)}/>
              </Button>
            </Box>

            {selectedFileCount[item.title] ? (
              <Box mr="10px">
                <Button
                  onClick={(e) => handleUploadFile(e, item.title, item.name)}
                  sx={{ backgroundColor: "#404040",
                  '&:hover': {
                    backgroundColor: "#525252",
                  }, }} 
                  variant="contained" component="label">
                  Upload Now
                </Button>
              </Box>
            ):""}

            <Box mr="10px">
              <Button
              onClick={() => handleManageUploads(item.title)}
                sx={{ backgroundColor: "#404040",
                '&:hover': {
                  backgroundColor: "#525252",
                }, }}
                variant="contained"
              >
                Manage Upload
              </Button>
            </Box>
          </Box>
        </Box>
      ))}

      {/* Render pagination */}
      <Box display="flex" justifyContent="center" mt="20px">
        <ReactPaginate
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          breakClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"page-link"}
          initialPage={0}
          disablePageStyles={true}
          previousLabel={"previous"}
          nextLabel={"next"}
        />
      </Box>
    </Box>
  );
};

export default VerificationChecklist;