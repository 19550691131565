import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, TextField, Button, Checkbox, FormControlLabel } from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const DeclarationPoints = ({handleSubmission, handleLogout, formState, handleInputChange, handleCheckBoxChange}) => {
    return (
        <Box width="880px">
            <Box padding="10px" bgcolor="#1E3A8A" width="100%" mt="80px">
                <Typography color="white">
                    Declaration & Agreement
                </Typography>
            </Box>

            <List>
                <ListItem>
                    <ListItemIcon>
                        <RadioButtonCheckedIcon sx={{
                            color: '#1E3A8A'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="I hereby confirm that I understand “Personal Information” in this context means personal information as described in Chapter 1 of the Protection of Personal Information Act, No 4 of 2013, but is not information provided, or which is collected from me or other third parties, before and/or during the verification process and/or thereafter" />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <RadioButtonCheckedIcon  sx={{
                            color: '#1E3A8A'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="In authorizing the verification referred to above, I confirm that I have not been forced/ coerced into signing and supplying the above information." />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <RadioButtonCheckedIcon  sx={{
                            color: '#1E3A8A'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="I understand that privacy is important to the Responsible Parties and the Responsible Parties will use reasonable efforts in order to ensure that any Personal Information in their possession or processed on their behalf is kept confidential, stored in a secure manner and processed in terms of South African law and or applicable Data Protection Legislation, for the purposes I have authorised." />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <RadioButtonCheckedIcon  sx={{
                            color: '#1E3A8A'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="I declare that the above information provided is both true, complete and accurate to the best of my knowledge." />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <RadioButtonCheckedIcon  sx={{
                            color: '#1E3A8A'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="I acknowledge receipt of and accept the terms of this undertaking." />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <RadioButtonCheckedIcon  sx={{
                            color: '#1E3A8A'
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="I confirm that I am authorised to sign this document / disclose this information on behalf of the Employer." />
                </ListItem>
            </List>

           <Box mt="60px">
                {/* DECLARATION AGREEMENT */}
                <FormControlLabel
                    control={
                    <Checkbox
                        color="primary"
                        checked={formState.declaration}
                        onChange={handleCheckBoxChange}
                        name="declaration"
                        style={{ zIndex: 1000 }}
                    />
                    }
                    label={<span style={{ fontStyle: 'italic', color: '#b91c1c', fontWeight: 'bold' }}>By completing this form, you consent to the above and the disclosure of the information to the Compensation Fund.</span>}
                />
           </Box>

            <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" padding="10px" mt="60px"
            sx={{
                "& > div": {gridColumn: "span 2"}
            }}>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Full Name" 
                    variant="filled" 
                    type="text"
                    name="name"
                    value={formState.name}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Contact Number" 
                    variant="filled" 
                    type="text"
                    name="contactNumber"
                    value={formState.contactNumber}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Company Name" 
                    variant="filled" 
                    type="text"
                    name="companyName"
                    value={formState.companyName}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Designation" 
                    variant="filled" 
                    type="text"
                    name="designation"
                    value={formState.designation}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                        id="filled-basic" 
                        label="Date" 
                        variant="filled" 
                        type="date"   // changed from "text" to "date"
                        name="date"
                        value={formState.date}
                        onChange={handleInputChange}
                        sx={{width: "100%"}}
                    />
                </Box>

                <Box sx={{ gridColumn: "span 1" }}>
                    <Button 
                    onClick={handleSubmission}
                    variant="contained" sx={{ height: "100%", width: "100%" }}>
                        Submit Submission
                    </Button>
                </Box>

                <Box sx={{ gridColumn: "span 1" }}>
                    <Button 
                    onClick={handleLogout}
                    variant="contained"
                    padding="20px" 
                    sx={{ 
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#404040",
                        '&:hover': {
                        backgroundColor: "#525252",
                        }, }} >
                        Exit Form Submission
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default DeclarationPoints;