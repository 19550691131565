import { Box, Typography, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";

// Assets
import Logo1 from '../../assets/1.png';
import Logo2 from '../../assets/2.png';

// Modals
import SuccessModal from '../../models/Success';
import ErrorModal from '../../models/Error';
import LoadingModal from '../../models/Loading';
import ManageModal from '../../models/Manage';
import DeleteModal from '../../models/Delete';

// Components
import EmployerDetails from "../../components/EmployerDetails";
import VerificationChecklist from "../../components/VerificationChecklist";
import DeclarationPoints from "../../components/DeclartionPoints";
import SubmissionProgress from "../../components/SubmissionProgress";
import UploadingModal from "../../models/Uploading";

const Index = () => {
    const [successModel, setSuccessModel] = useState(false);
    const [errorModel, setErrorModel] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Kindly be patient as we're fetching your information...");
    const [triggerLoader, setTriggerLoader]= useState(false);
    const [manageModal, setManageModal] = useState(false);
    const [deleteModal, setDeleteModal]= useState(false);
    const [uploadingModal, setUploadingModal] = useState(false);

    const [uploads, setUploads] = useState([]);
    const [selectedUpload, setSelectedUpload] = useState("");
    const uid = localStorage.getItem("id") ? localStorage.getItem("id"):localStorage.setItem("id", null);

    useEffect(() => {
        const handleFetch = async () => {
            if(localStorage.getItem("email").toString()){
                setLoadingModal(true);
                const { data } = await axios.post('/api/data/fetch', {
                    employer: localStorage.getItem('email').toString(),
                  }, {
                    headers: { 'Content-Type': 'application/json' },
                  });
                  localStorage.setItem("id", data.uid)
                  setUploads(data.files);
                  if (data.data[0].submission.length > 0) {
                        const submission = data.data[0].submission[0];

                        Object.keys(submission).forEach((key) => {
                        if (key in formState) {
                            setFormState((prevState) => ({
                            ...prevState,
                            [key]: submission[key],
                            }));
                        }
                        });
                    }
                setLoadingModal(false);
            }
        }

        if(!successModel && !deleteModal){
            handleFetch();
        }
    },[triggerLoader, successModel, deleteModal])

    const [files, setFiles] = useState([]);
    const [selectedFileCount, setSelectedFileCount] = useState(0);
    const [formState, setFormState] = useState({
        emplyerName:"",
        cfRegistrationNumber:"",
        uifRegistrationNumber:"",
        cipcRegistrationNumber:"",
        sarsTaxNo:"",
        nrOfEmployers:"",
        employersRegisteredToCF:"",
        employeesRegisteredToCFNo:"",
        businessAddress:"",
        city:"",
        province:"",
        postalCode:"",
        postalAddress:"",
        employerTelephone:"",
        mobileTelephone:"",
        employersEmail:"",
        consultantEmail:"",
        consultantTelephone:"",
        cipc: "",
        cf: "",
        letter: "",
        photo: "",
        earnings: "",
        payrolls: "",
        bank: "",
        payments: "",
        csd: "",
        claims: "",
        consent: "",
        name: "",
        contactNumber: "",
        companyName: "",
        designation: "",
        date: "",
        declaration: false,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    };

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
        
    }

    const resetForm = () => {
        setFormState(initialState);
    };

    const handleFileUpload = (event, folder) => {
        setSelectedFileCount(prevCounts => ({
          ...prevCounts,
          [folder]: event.target.files.length,
        }));
      
        const newFiles = [];
      
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          const reader = new FileReader();
      
          reader.onload = () => {
            newFiles.push({
              folder: folder,
              name: file.name,
              contents: reader.result,
            });
      
            setFiles(newFiles);
          };
          reader.readAsDataURL(file);
        }
      };

    const handleUploadFile = async (event, folder, name) => {
        const formData = new FormData();
      
        files.forEach(file => {
          const blob = new Blob([file.contents], { type: 'application/octet-stream' });
          formData.append('files', blob, `${file.folder}/${file.name}`);
        });

        try {
            setUploadingModal(true);
            await axios.post(`/api/sub/update/upload/${uid}/${files[0].folder}/${name}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then(() => {
                setSuccessModel(true);
                setSelectedFileCount(0);
            });
            setUploadingModal(false);
        } catch(err){
            setErrorMessage('Please try again later...');
            setUploadingModal(false);
            setErrorModel(true);
        }
    }

    const handleManageUploads = (folder) => {
        setSelectedUpload(folder);
        setManageModal(true);
    }
      
    const handleSubmission = async () => {
        const formData = new FormData();
      
        files.forEach(file => {
          const blob = new Blob([file.contents], { type: 'application/octet-stream' });
          formData.append('files', blob, `${file.folder}/${file.name}`);
        });
      
        if (formState.declaration === false) {
          setErrorModel(true);
          setErrorMessage('Please ensure to upload your signed Consent Form.');
          return;
        }
      
        try {
          const data = await axios.post('/api/sub/submission/update', {
            employer: localStorage.getItem('email').toString(),
            submission: formState
          }, {
            headers: { 'Content-Type': 'application/json' }
          }).then(async (res) => {
            localStorage.setItem("id", res.data.data._id);
          });
          setSuccessModel(true);
        } catch (err) {
            setErrorModel(true);
            setErrorMessage('Please try again later, if this issue persists kindly contact us via email.');
        }
    };
      
    const handleFileDelete = async (folder, file) => {
        try {
            setManageModal(false);
            setDeleteModal(true);
            const response = await axios.post(`/api/file/remove`, {
                employer:localStorage.getItem('email'),
                folderName:folder,
                fileToDelete: file
            },{
                headers: { 'Content-Type': 'application/json' },
            })
        } catch (err) {
            setErrorModel(true);
            setDeleteModal(false);
            setErrorMessage('Please try again later, if this issue persists please contact us via email.');
        }
    }

    const handleLogout = () => {
        localStorage.removeItem("email");
        window.location.reload();
    }

    return (
        <>
        {successModel ? (
            <SuccessModal setTrigger={setTriggerLoader} data={formState} setOpen={setSuccessModel} open={successModel} message={"Kindly take note that we have received your submission."}/>
        ):""}

        {errorModel ? (
            <ErrorModal setOpen={setErrorModel} open={errorModel} message={errorMessage}/>
        ):""}

        {loadingModal ? (
            <LoadingModal open={loadingModal} message={loadingMessage}/>
        ):""}

        {manageModal ? (
            <ManageModal handleFileDelete={handleFileDelete} setOpen={setManageModal} open={manageModal} uploads={uploads} selectedUpload={selectedUpload}/>
        ):""}

        {deleteModal ? (
            <DeleteModal setOpen={setDeleteModal} open={deleteModal}/>
        ):""}

        {uploadingModal ? (
            <UploadingModal open={uploadingModal}/>
        ):""}

            <Box width="100%" height="100%" display="grid" justifyContent="center" m="40px">
                {/* LOGO */}
                <Box display="flex" justifyContent="between">
                    <img src={Logo1} alt="logo-1" height="100px" style={{marginRight: 400}}/>
                    <img src={Logo2} alt="logo-1" height="100px"/>
                </Box>

                {/* TITLE */}
                <Box textAlign="center" mt="30px">
                    <Typography fontWeight="bold">
                        Verification of Employer for Compensation Fund
                    </Typography>
                    <Typography fontWeight="bold">
                        OCCUPATIONAL INJURIES AND DISEASES ACT 130 OF 1993
                    </Typography>
                </Box>

                {/* SUBMISSION PROGRESSION */}
                <SubmissionProgress data={formState}/>
                
                {/* Employer's Details */}
                <EmployerDetails 
                handleInputChange={handleInputChange}
                formState={formState} 
                setFormState={setFormState}/>

                {/* VERIFICATION CHECKLIST */}
                <VerificationChecklist
                handleManageUploads={handleManageUploads}
                selectedFileCount={selectedFileCount}
                handleUploadFile={handleUploadFile}
                handleFileUpload={handleFileUpload}/>

                {/* DECLARATION POINTS */}
                <DeclarationPoints 
                handleSubmission={handleSubmission}
                handleCheckBoxChange={handleCheckBoxChange}
                handleInputChange={handleInputChange}
                formState={formState} 
                setFormState={setFormState}
                handleLogout={handleLogout}/>
            </Box>
        </>
    );
}

export default Index;

const initialState = {
    emplyerName:"",
    cfRegistrationNumber:"",
    uifRegistrationNumber:"",
    cipcRegistrationNumber:"",
    sarsTaxNo:"",
    nrOfEmployers:"",
    employersRegisteredToCF:"",
    employeesRegisteredToCFNo:"",
    businessAddress:"",
    city:"",
    province:"",
    postalCode:"",
    postalAddress:"",
    employerTelephone:"",
    mobileTelephone:"",
    employersEmail:"",
    consultantEmail:"",
    consultantTelephone:"",
    cipc: "",
    cf: "",
    letter: "",
    photo: "",
    earnings: "",
    payrolls: "",
    bank: "",
    payments: "",
    csd: "",
    claims: "",
    consent: "",
    name: "",
    contactNumber: "",
    companyName: "",
    designation: "",
    date: "",
    declaration: false,
};