const ChecklistItems = [
    {
      title: "CIPC registration number",
      name: "cipc",
      items: [
        "CIPC Number", 
        "Copy of CIPC registration papers (COR 14.1 and 14.3)"],
    },
    {
      title: "CF Registration",
      name: "cf",
      items: [
        "CF number",
        "Registration documentation confirming registration at CF",
        "Proof of UIF Registration",
        "Proof of Registration with Statutory Bodies (If applicable)",
      ],
    },
    {
      title: "Letter of Good Standing",
      name: "letter",
      items: [
        "Extract from e-filing or latest LOGS; or",
        "Letters of good standing received by Employers from 2016 to 2021.",
      ],
    },
    {
      title: "Photo of company",
      name: "photo",
      items: [
        "Take a photo of the premises to confirm the existence of the company and if possible, the type of work being conducted. ** For Building contractors for instance, the employer must refer you a current site",
      ],
    },
    {
      title: "Return of Earnings",
      name: "earnings",
      items: [
        "Return of Earnings submitted to the CF from 2016 to 2021",
        "Notice of Assessment/ Invoices from the CF from 2016 to 2021",
        "Audited/ Independently Reviewed/ Compiled and Approved Annual Financial Reports from 2016 to 2021",
      ],
    },
    {
      title: "Detailed payrolls",
      name: "payrolls",
      items: [
        "A Detailed copy of payroll from 2016 to 2021",
        "An EMP201/ EMP 501 for the audit years (2016 to 2021)",
        "Detailed OID report (Sage payroll) aka COID report on other payroll solutions from 2016 to 2021",
        "Employee register containing the ID or Passport number of the employee as well as the number employees within the workforce; or",
        "Any other proof of the number of employees that are employed at the company/employer.",
      ],
    },
    {
      title: "Bank Confirmation Letters",
      name: "bank",
      items: [
        "Bank confirmation letters of all bank accounts utilised by the Employers",
      ],
    },
    {
      title: "Proof of Payment",
      name: "payments",
      items: [
        "Proof of Payments indicating the contributions paid to CF from 2016 to 2021",
        "Letters of good standing received by Employers from 2016 to 2021.",
      ],
    },
    {
      title: "Company CSD Report",
      name: "csd",
      items: [
        "Full CSD report containing the:",
        "Industry Classification;",
        "Address;",
        "Bank Account;",
        "Tax Information;",
        "BEE Information;",
        "Ownership Information;",
        "Director Information;",
      ],
    },
    {
      title: "Claims logged or submitted to compensation fund",
      name: "claims",
      items: [
        "Proof of claims submitted to CF",
      ],
    },
    {
      title: "Consent Form",
      name: "consent",
      items: [
        "Completed and signed consent form.",
      ],
    },
]


export default ChecklistItems