import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none', // removed the border
    borderRadius: 2, // added border radius
    boxShadow: 24,
    p: 4,
  };

const SuccessModal = ({open, setOpen, uploads, selectedUpload, handleFileDelete, timeoutDuration=3500}) => {
    const selectedFiles = uploads.filter(item => item.folder.endsWith('/') && item.folder.slice(0, -1) === selectedUpload);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (open && selectedFiles.length <= 0) {
            const timeoutId = setTimeout(() => {
                handleClose();
            }, timeoutDuration);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [open, timeoutDuration]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography 
                    color="#1E3A8A"
                    fontWeight="bold"
                    id="modal-modal-title">
                        Manage Uploads!
                    </Typography>
                    <Box width="400px" display="grid" mt="20px" sx={{
                        fontSize: "12px"
                    }}>
                        {selectedFiles.length > 0 ? (
                            <>
                                {selectedFiles[0].files.map((file, i) => (
                                    <Box mt="5px" mb="5px" padding="5px" display="flex" justifyContent="space-between"
                                    sx={{
                                        borderRadius: 1,
                                        fontSize: "12px",
                                        cursor: 'pointer',
                                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #e0e0e0",
                                        transition: "box-shadow 0.3s ease-in-out",
                                        "&:hover": {
                                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)"
                                        }
                                    }}>
                                        <Typography sx={{
                                            fontSize: '10px'
                                        }}>
                                        {file}
                                        </Typography>
        
                                        <DeleteIcon 
                                        onClick={() => handleFileDelete(selectedUpload, file)}
                                        height="8px"
                                        sx={{
                                        transition: "color 0.3s ease-in-out",
                                        "&:hover": {
                                            color: "#800000",
                                        }
                                        }}/>
                                    </Box>
                                ))}
                            </>
                        ):(
                            <Typography  mt="20px" mb="5px"display="flex" justifyContent="space-between">
                                You have not uploaded any files for your submission.
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default SuccessModal
