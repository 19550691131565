import { Box, Typography, TextField } from "@mui/material";


const EmployerDetails = ({formState, handleInputChange}) => {
    return (
        
        <Box>
            <Box padding="10px" bgcolor="#1E3A8A" width="100%" mt="40px">
                <Typography color="white">
                    Employer's Details
                </Typography>
            </Box>

            {/* EMPLOYER DETAILS FORM */}
            <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" padding="10px"
            sx={{
                "& > div": {gridColumn: "span 2"}
            }}>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Name of Employer" 
                    variant="filled" 
                    type="text"
                    name="emplyerName"
                    value={formState.emplyerName}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="CF Registration Number" 
                    variant="filled" 
                    type="text"
                    name="cfRegistrationNumber"
                    value={formState.cfRegistrationNumber}
                    onChange={handleInputChange}    
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="UIF Registration Number" 
                    variant="filled" 
                    type="text"
                    name="uifRegistrationNumber"
                    value={formState.uifRegistrationNumber}
                    onChange={handleInputChange}       
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="CIPC Registration Number" 
                    variant="filled" 
                    type="text"
                    name="cipcRegistrationNumber"
                    value={formState.cipcRegistrationNumber}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="SARS Tax No" 
                    variant="filled" 
                    type="text"
                    name="sarsTaxNo"
                    value={formState.sarsTaxNo}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="How many employees does your company employ?" 
                    variant="filled" 
                    type="text"
                    name="nrOfEmployers"
                    value={formState.nrOfEmployers}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Are all employees registered for Compensation Fund? (Yes/No)" 
                    variant="filled" 
                    type="text"
                    name="employersRegisteredToCF"
                    value={formState.employersRegisteredToCF}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="If “No”, please provide information as to the circumstances" 
                    variant="filled" 
                    type="text"
                    name="employeesRegisteredToCFNo"
                    value={formState.employeesRegisteredToCFNo}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Business Address" 
                    variant="filled" 
                    type="text"
                    name="businessAddress"
                    value={formState.businessAddress}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="City/Town" 
                    variant="filled" 
                    type="text"
                    name="city"
                    value={formState.city}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Province" 
                    variant="filled" 
                    type="text"
                    name="province"
                    value={formState.province}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Postal Code" 
                    variant="filled" 
                    type="text"
                    name="postalCode"
                    value={formState.postalCode}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Postal Address" 
                    variant="filled" 
                    type="text"
                    name="postalAddress"
                    value={formState.postalAddress}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Employer Telephone No" 
                    variant="filled" 
                    type="text"
                    name="employerTelephone"
                    value={formState.employerTelephone}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Mobile Telephone No" 
                    variant="filled" 
                    type="text"
                    name="mobileTelephone"
                    value={formState.mobileTelephone}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
                <Box sx={{ gridColumn: "span 1" }}>
                    <TextField 
                    id="filled-basic" 
                    label="Employer email address" 
                    variant="filled" 
                    type="text"
                    name="employersEmail"
                    value={formState.employersEmail}
                    onChange={handleInputChange}
                    sx={{width: "100%"}}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default EmployerDetails;