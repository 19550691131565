import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LinearProgress } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none', // removed the border
    borderRadius: 2, // added border radius
    boxShadow: 24,
    p: 4,
  };

const SuccessModal = ({data, open, setOpen, message, timeoutDuration = 5500}) => {
    const filtered = Object.keys(data).filter(item => item !== "consultantEmail" && item !== "consultantTelephone")
    const receivedCount = Object.values(filtered).filter(value => value).length;
    const totalCount = Object.values(filtered).length;
    const progress = (receivedCount / totalCount) * 100;

    useEffect(() => {
        if (open) {
            const timeoutId = setTimeout(() => {
                handleClose();
            }, timeoutDuration);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [open, timeoutDuration]);

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography 
                color="#1E3A8A"
                fontWeight="bold"
                id="modal-modal-title">
                    Successful Submission!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                </Typography>

                <Box mt="40px">
                    <Typography variant="subtitle1">
                        {receivedCount} of {totalCount} fields received
                    </Typography>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default SuccessModal
